import { makeStyles } from 'tss-react/mui';

import type { PropsWithChildren } from 'react';

const useStyles = makeStyles({ name: 'app' })(() => ({
  root: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
  },
}));

export const AppRoot = (props: PropsWithChildren<unknown>): JSX.Element => {
  const { classes } = useStyles();

  return <div
    className={ classes.root }
    data-testid="app-root"
  >
    { props.children }
  </div>;
};
